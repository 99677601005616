import React from 'react'
import Footer from '../Footer'
import Covid from './Covid'
import Customer from './Customer'
import Header from './Header'
import WhyUs from './WhyUs'

const Home = () => {
    return (
        <div>
          <Header /> 
          <WhyUs />
          <Customer />
          <Covid />
          <Footer/>
        </div>
    )
}

export default Home
