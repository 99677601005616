import React from 'react'
import styled from 'styled-components'
import header from '../../images/header.png'

const Head = styled.div`
    width: 100%;
    width: 100%;
    height: 48vw;
    #bg {
        position: absolute;
        left: 0;
        width: 100%;
        height: 48vw;
        img {
            width: 100%;
            height: 48vw;
        }
    }

    #mask {
        width: 100%;
        height: 48vw;
        position: absolute;
        background: rgba(27,27,27,0.7);

        #text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-align: center;
            h1 {
                margin: 0 auto;
                margin-bottom: 2vw;
                padding: 0;
                line-height: 100%;
                font-family: 'Segoe Print';
                font-size: 4vw;
            }
            h2 {
                margin: 0 auto;
                padding: 0;
                line-height: 100%;
                font-weight: 300;
                font-size: 2vw;
                font-family: 'Segoe Script';
            }
        }
    }
`

const Header = () => {
    return (
        <Head>
            
            {/* Background Image */}
            <div id="bg">
                <img src={header} alt="" />
            </div>

            {/* Black Mask over image */}
            <div id="mask">
                {/* Centered Text */}
                <div id="text">
                    <h1>Buddy's Barkery</h1>
                    <h2>Only The Best for Your Pooch!</h2>
                </div>
            </div>

        </Head>
    )
}

export default Header
