import React from 'react'
import styled from 'styled-components'
import buddy from '../../images/buddy.png'
import di from '../../images/di.png'
import maherbuddy from '../../images/maherbuddy.png'

const Elem = styled.div`
    width: 100%;
    padding: 4vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h1 {
        font-size: 3.8vw;
        line-height: 120%;
        margin:0;
        padding: 0;
    }
    p {
        width: 85%;
        margin: 2vw auto;
        font-weight: 300;
        font-size: 1.4vw;
        span {
            font-weight: 600;
            font-size: 1.6vw;
            font-style: italic;
        }
    }
    .story {
        background: #9F4848;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .images {
            width: 80%;
            margin: 0 auto;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-end;
            img {
                width: 20vw;
                height: 20vw;
                border-radius: 50%;
                border: 1px solid white;
            }
        }
        #quote {
            font-family: 'Times New Roman';
            color: white;
            font-size: 8vw;
            margin: 0;
            padding: 0;
            padding-top: 1vw;
            line-height: 100%;
        }
        .item {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            p {
                color: white;
                font-weight: 500;
                font-size: 1.2vw;
                width: 60%;
                padding: 0;
                margin: 2vw 0;
                text-align: left;
            }
        }
    }
    .bold {
        font-weight: 500;
        span {
            padding: 0 0.5vw;
            font-weight: 700;
            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }
    
    @media only screen and (max-width: 700px) {
        padding: 4vw 0;
        h1 {
            font-size: 6vw;
            margin:0;
            padding: 0;
        }
        p {
            width: 85%;
            margin: 0 auto;
            margin-top: 3vw;
            margin-bottom: 5vw;
            font-size: 3vw;
            span {
                font-size: 3.4vw;
            }
        }
        .story {
            padding: 0;
            .images {
                width: 90%;
                margin: 0 auto 3vw auto;
                margin-top: -3vw;
                img {
                    width: 20vw;
                    height: 20vw;
                }
            }
            #quote {
                font-size: 16vw;
                margin: 0;
                padding: 0;
                padding-top: 1vw;
                line-height: 100%;
            }
            .item {
                margin: 0 auto;
                p {
                    font-size: 2.5vw;
                    width: 80%;
                    padding: 0;
                    margin: 2vw 0;
                }
            }
        }
    }
`

const Founder = () => {
    return (
        <Elem>
            <h1>Founders' Story</h1>
            <p>
                Let me say this out loud- the best day of my life was when me and my sister got a puppy. Whatever he did, brought smiles to our faces. The moment he came home, we knew he was going to be our best friend. Hence he was named <span>BUDDY</span>!
            </p>
            <div className="story">
                <h1 id="quote">&ldquo;</h1>
                <div className="images">
                    <img src={maherbuddy} />
                    <img src={buddy} />
                    <img src={di} />
                </div>
                <div className="item">
                    <p>
                        Buddy brought immense happiness to the entire family. He lifted our spirits and showed us what compassion and unconditional love looks like. He truly became my best friend. Even when Covid-19 had taken the world by a storm, Buddy continued to make us smile and help us see past the negatives. I decided I wanted to turn the lockdown into something positive and explore baking. I discovered that baking was not only something I was naturally good at, but also was a form of meditation for me! I created everything from cakes to cookies; from bread and tarts. Everything was perfect... until the middle of lockdown early 2020. Buddy suddenly started falling a bit sick. We called the doctor, but the clinic was closed due to the pandemic. We video called them so they could examine him better. We were told it seems to be a cold and they gave us a 5 day medicine course. 
                    </p>
                </div>
                <div className="item">
                    <p>
                        Unfortunately, Buddy passed away on the 3rd morning after the call and that's when it seemed like everything was taken away from us. Lockdown was already hard to cope but with Buddy's death the whole world faded away. Our family was in shock. I had just lost my best friend. It was so difficult to cope with, there was nowhere to go and nothing to do to distract my mind from the death of our beloved family member. My sister who currently lives in the USA was also in shock from the sudden death of Buddy. Even though she had not spent as much time with him, he held a special place in her heart as well. We were hoping time would heal us but days passed by and everything still seemed gloomy. 

                    </p>
                </div>
                <div className="item">
                    <p>
                        In an attempt to help me cope with this, my sister suggested I start baking treats for dogs. That way I could keep myself distracted and at the same time, I could utilize my new found passion for baking and my love for dogs. That is when I decided to open up Buddy's Barkery, where every order helps us contribute towards the welfare of dogs in need in Ahmedabad. We thought giving back to dogs in need is the best way to keep Buddy's memory alive. We have successfully donated part of our first month's profits to Jivdaya Charitable Trust and were able to provide medicine and food to the dogs in need. 
                    </p>
                </div>
                <h1 id="quote">&rdquo;</h1>
            </div>
            <p className="bold">
                We started Buddy's Barkery to make yummy treats and bring happiness to dogs and their owners, but at the same time we want to make sure our treats keep dogs healthy.<br/><br/>
                In Buddy's Barkery<span className="italic">(<a target="_blank" href="https://instagram.com/buddys_barkery">@buddys_barkery</a>)</span> we use 0 preservatives, 0 added sugar, no fondant and only fresh, pure, dog friendly ingredients. The health, safety and happiness of dogs is our priority.
            </p>
        </Elem>
    )
}

export default Founder
