import React from 'react'
import styled from 'styled-components'
import vector from '../../images/vector.png'
import quote from '../../images/quote.png'

const Elem = styled.div`
    width: 100%;
    height: 48vw;
    margin: 0;
    margin-top: 4vw;
    padding: 2vw 0 8vw 0;
     #bottom {
        position: absolute;
        left: 0;
        transform: translateY(5vw);
        width: 12.031vw;
    }
    #redbg {
        position: absolute;
        width: 50%;
        left: 50%;
        background: #9f4848;
        height: 48vw;
    }
    .heading {
        width: 40%;
        margin: 0 auto;
        margin-top: 3vw;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        h1 {
            text-align: left;
            line-height: 100%;
            font-size: 3vw;
            margin: 0;
            padding: 0;
            padding-right: 2vw;
        }
        .right {
            transform: translate(0,0);
            text-align: right;
            color: white;
        }
    }
    .content {
        transform: translate(0,0);
        z-index: 5;
        width: 90%;
        margin: 0 auto;
        padding-top: 10vw;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        .item {
            background: white;
            padding: 2vw 0;
            width: 25vw;
            height: 16vw;
            border-radius: 1vw;
            box-shadow: inset 0.5px 0px 2px 1px rgba(0,0,0,0.25);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            h3, p {
                margin: 0.5vw auto;
                text-align: center;
            }
            .quote {
                padding: 1.5vw 0;
                height: 3vw;
                width: 3vw;
            }
            h3 {
                margin: 1vw 0;
                line-height: 120%;
                font-size: 1.2vw;
                font-family: 'Poppins';
                font-weight: bold;
            }
            p {
                width: 19.375vw;
                font-size: 0.82vw;
                font-family: 'Poppins';
                color: #555555;
                font-weight: bold;
            }
        }
    }
`

const Customer = () => {
    return (
        <Elem>
            <div id="redbg"></div>
            <div className="heading">
                <h1 className="left">Customer</h1>
                <h1 className="right">Reviews</h1>
            </div>
            <div className="content">
               <div className="item">
                   <img className="quote" src={quote} />
                   {/* <h3>Janmejay Shastri</h3> */}
                   <p>
                       It was a delight to talk to maher the other day. She made these doggilicious treats keeping in mind what Blu and sultan (my 2 dogs) like. We also talked about dog allergies and how she incorporates dietary needs of a pupper in her baked treats.
                   </p>
               </div>

               <div className="item">
                   <img className="quote" src={quote} />
                   {/* <h3>Someone Something</h3> */}
                   <p>
                       My dog Elsa loved the frozen treats, baked treats, pawcakes and cake for her birthday. Even her friends ate it in one go. Super fresh and healthy products!
                   </p>
               </div>

               <div className="item">
                   <img className="quote" src={quote} />
                   {/* <h3>Maher Thakkar</h3> */}
                   <p>
                       Kiara could not stop eating the baked treats. She wanted more and more of them. Can’t wait to order again.
                   </p>
               </div>
            </div>
            <img id="bottom" src={vector} />
        </Elem>
    )
}

export default Customer
