import React from 'react'
import styled from 'styled-components'
import header from '../../images/about.png'

const Head = styled.div`
    width: 100%;
    width: 100%;
    height: 86vh;
    #bg {
        position: absolute;
        left: 0;
        width: 100%;
        height: 86vh;
        img {
            width: 100%;
            height: 86vh;
        }
    }

    #mask {
        width: 100%;
        height: 86vh;
        position: absolute;
        background: rgba(27,27,27,0.7);

        #text {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-align: center;
            h1 {
                margin: 0 auto;
                margin-bottom: 5vw;
                padding: 0;
                line-height: 120%;
                font-family: 'Segoe Print';
                font-size: 4vw;
            }
            h2 {
                width: 100%;
                margin: 0 auto;
                padding: 0;
                line-height: 130%;
                font-weight: 300;
                font-size: 3vw;
                font-family: 'Segoe Script';
            }
        }
    }
    @media only screen and (max-width: 700px) {
        height: 60vw;
        #bg {
            height: 60vw;
            img {
                height: 60vw;
            }
        }
        #mask {
            height: 60vw;
            #text {
                top: 50%;
                left: 50%;
                h1 {
                    font-size: 7vw;
                    margin-bottom: 8vw;
                }
                h2 {
                    width: 80%;
                    margin: 0 auto;
                    padding: 0;
                    font-size: 4vw;
                }
            }
        }
    }
`

const Header = () => {
    return (
        <Head>
            {/* Background Image */}
            <div id="bg">
                <img src={header} alt="" />
            </div>

            {/* Black Mask over image */}
            <div id="mask">
                {/* Centered Text */}
                <div id="text">
                    <h1>About<br/>Buddy's Barkery</h1>
                    <h2>It all started with our new puppy - 'BUDDY'!</h2>
                </div>
            </div>
        </Head>
    )
}

export default Header
