import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import errorImage from '../images/errorImage.png'

const Elem = styled.div`
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        padding-top: 8vw;
        width: 30%;
    }
`

const Error = () => {
    return (
        <Elem>
            <img src={errorImage} />
        </Elem>
    )
}

export default Error
