import React from 'react'
import Footer from './Footer'
import Covid from './Home/Covid'

const Covid19 = () => {
    return (
        <div style={{marginTop: "12vw"}}>
           <Covid />
           <Footer/>
        </div>
    )
}

export default Covid19
