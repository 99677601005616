import React from 'react'
import styled from 'styled-components'
import sanitizer from '../../images/sanitizer.png'
import kitchen from '../../images/kitchen.png'
import gloves from '../../images/gloves.png'
import paytm from '../../images/paytm.png'
import covid from '../../images/covid.png'
import vector from '../../images/vector.png'

const CovidElem = styled.div`
    width: 100%;
    margin: 0;
    margin-top: 4vw;
    padding: 2vw 0 8vw 0;
    #bottom {
        position: absolute;
        left: 0;
        transform: translateY(0vw);
        width: 12.031vw;
    }
    h1 {
        width: 80%;
        margin: 0 auto;
        line-height: 100%;
        font-size:  3.698vw;
        text-align: center;
    }
    .content {
        width: 84%;
        margin: 0 auto;
        margin-top: 1.458vw;
        display: flex;
        justify-content: space-between; 
        align-items: center;
        .list {
            .item {
                width: 45.313vw;
                margin: 2vw 0;
                display: flex;
                justify-content: space-between; 
                align-items: center;
                img {
                    width: 3.125vw;
                }
                .point {
                    width: 38.594vw;
                    h3 {
                        font-size: 2.292vw;
                        line-height: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    p {
                        font-size: 1.719vw;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        .right {
            width: 28.177vw;
            img {
                display: block;
                margin: 0 auto;
                width: 92.5%;
            }
        }
    }
`

const Covid = () => {
    return (
        <CovidElem>
            <h1>COVID-19 Regulations</h1>
            <div className="content">
                {/* Left section for bullet points */}
                <div className="list">
                    <div className="item">
                        <img src={sanitizer} />
                        <div className="point">
                            <h3>Wash and Sanitize</h3>
                            <p>All Fresh Ingredients before Using</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={kitchen} />
                        <div className="point">
                            <h3>Sanitize the kitchen</h3>
                            <p>After and before every order</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={gloves} />
                        <div className="point">
                            <h3>Wear gloves and masks</h3>
                            <p>while preparing food for your furry friend</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={paytm} />
                        <div className="point">
                            <h3>Accept Digital Payments (PAYTM)</h3>
                            <p>So no cash handling</p>
                        </div>
                    </div>
                </div>

                {/* Right section for Products Link */}
                <div className="right">
                    <img src={covid} />
                </div>
            </div>
            {/* <img id="bottom" src={vector} /> */}
        </CovidElem>
    )
}

export default Covid
