import React from 'react'
import Footer from './Footer'
import styled from 'styled-components'
import hc from '../images/hc.png'
import mobileCust from '../images/mobileCust.png'

const Elem = styled.div`
    width: 100%;
    padding: 4vw 0;
    h1 {
        text-align: center;
        margin: 3vw auto;
        font-size: 4vw;
        padding: 0;
        font-family: 'Poppins';
        letter-spacing: 0.1vw;
        line-height: 110%;
    }
    .images {
        .mobile {
            display: none;
        }
        width: 95%;
        margin: 2vw auto;
        padding: 2vw 0;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 98%;
        }
    }
    @media only screen and (max-width: 700px) {
        width: 100%;
        padding: 4vw 0;
        h1 {
            font-size: 5vw;
        }
        .images {
            .mobile {
                display: flex;
            }
            .desktop {
                display: none;
            }
            width: 95%;
            margin: 2vw auto;
            padding: 2vw 0;
            img {
                width: 98%;
            }
        }
    }
`

const HappyCustomers = () => {
    return (
        <div>
            <Elem>
                <h1>Happy Customers</h1>
                <div className="images">
                        <img className="desktop" src={hc} />
                        <img className="mobile" src={mobileCust} />
                    {/* <div className="item">
                        <img src={hc2} />
                    </div>
                    <div className="item">
                        <img src={hc3} />
                    </div>
                    <div className="item">
                        <img src={hc4} />
                    </div>
                    <div className="item">
                        <img src={hc5} />
                    </div>
                    <div className="item">
                        <img src={hc6} />
                    </div>
                    <div className="item">
                        <img src={hc7} />
                    </div> */}
                </div>
            </Elem>
            <Footer />      
        </div>
    )
}

export default HappyCustomers
