import React from 'react'
import styled from 'styled-components'
import call from '../images/call.png'
import insta from '../images/insta.png'
import vector from '../images/vector.png'

const Elem = styled.div`
    * {
        box-sizing:border-box;
    }
    #vector {
        position: fixed;
        top: 14vh;
        right: 0;
        width: 10vw;
    }
    width: 100%;
    height: 80vh;
    padding: 6vh 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    label, input {
        font-family: 'Roboto';
    }
    .white {
        min-width: 60vw;
        height: 55vh;
        background: #FFDBE1;
        box-shadow: 1px 1px 6px 2px rgba(0,0,0,0.17);
        border-radius: 1vw;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .item {
            border-radius: 1vw;
            background: white;
            padding: 1vw;
            margin: 1vw 0;
            width: 30%;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            img {
                width: 2vw;
                margin: 1vw 0;
            }
            h4 {
                line-height: 130%;
                font-size: 1.4vw;
                padding: 0;
                margin: 0 0 1vw 0;
            }
            p {
                font-size: 1vw;
                padding: 0.5vw 1.5vw;
                border-radius: 2vw;
                box-shadow: inset 0px 0px 4px rgba(0,0,0,0.17);
                &:hover {
                    background: rgba(27,27,27,0.7);
                    a {
                        color: white;
                        font-weight: 500; 
                    }
                }
                a {
                    color: black;
                    text-decoration: none;
                }
            }
        }
    }
    
    @media only screen and (max-width: 700px) {
        #vector {
            width: 20vw;
        }
        height: 75vh;
        padding: 6vh 0 0 0;
        display: flex;
        .white {
            flex-direction: column;
            .item {
                width: 50%;
                img {
                    width: 10%;
                }
                h4 {
                    font-size: 2vw;
                }
                p {
                    margin: 1vw 0;
                    font-size: 1.8vw;
                }
            }
        }
    }
`

const Contact = () => {
    return (
        <Elem>
            <img id="vector" src={vector} />
            {/* <div className="white">
                <h2>Get In Touch!</h2>
                <input type="text" onChange={handleInput} name="name" placeholder="Name" value={details.name} />
                <input type="email" onChange={handleInput} name="email" placeholder="Email" value={details.email} />
                <textarea id="message" onChange={handleInput} name="message" value={details.message} placeholder="Message (Minimum 20 characters)"></textarea>
                <button id="button" onClick={handleSubmit}>Submit</button>
            </div> */}

            <div className="white">
                <div className="item">
                    <img src={call} />
                    <h4>Reach Us Via<br/>Text/Call : </h4>
                    <p><a href="tel:+919512599309">+91 9512599309</a></p>
                </div>
                <div className="item">
                    <img src={insta} />
                    <h4>Reach Us Via<br/>Our Social Handle : </h4>
                    <p><a href="https://instagram.com/buddys_barkery" target="_blank">buddys_barkery</a></p>
                </div>
            </div>
        </Elem>
    )
}

export default Contact
