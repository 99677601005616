import React from 'react'
import styled from 'styled-components'
import logo2 from '../images/logo2.png'
import {Link} from 'react-router-dom'

const Elem = styled.footer`
    width: 100%;
    background: #FFDBE1;
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        ul {
            width: 30%;
            padding: 0;
            list-style: none;
            text-align: center;
            li {
                margin: 2vw auto;
                a {
                    text-decoration: none;
                    color: #747474;
                    font-weight: 700;
                    font-size: 1vw;
                    &:hover {
                        border-bottom: 0.2vw solid black;
                    }
                }
            }
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30%;
            transform: translateY(-2vw);
            z-index: 2;
            padding: 4vw 0 0 0;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                img {
                    width: 50%;
                }
            }
        }
    }
    .line {
        margin: 1vw auto;
        width: 65%;
        height: 0.2vw;
        background: #747474;
        border-radius: 2vw;
    }
    .bottom {
        margin-top: 1vw;
        h4 {
            color: #747474;
            font-weight: 500;
            font-size: 1.2vw;
            letter-spacing: 0.05vw;
            line-height: 120%;
            margin: 0;
            padding: 0;
        }
    }
`

const Footer = () => {
    return (
        <Elem>
            <div className="top">
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/products">Products</Link></li>
                </ul>
                <ul>
                    <li><Link to="/customers">Happy Customers</Link></li>
                    <li><Link to="/donate">Donate</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
                <div className="logo">
                    {/* Title */}
                    <Link to="/"><img src={logo2} /></Link>
                </div>
            </div>
            <div className="line"></div>
            <div className="bottom">
                <h4>Copyright &copy; 2021 | Buddy's Barkery.</h4>
            </div>
        </Elem>
    )
}

export default Footer
