import {Link} from 'react-router-dom'
import '../styles/navbar.css'
import logo from '../images/logonav.png'

const Navbar = () => {
    return (
        <ul id="navbar">
            <li className="normal"><p><Link to="/">Home</Link></p></li>
            <li className="normal"><p><Link to="/about">About</Link></p></li>
            <li className="normal"><p><Link to="/products">Products</Link></p></li>
            <li className="logo">
                {/* Logo image */}
                <div id="logo">
                    <Link to="/"><img src={logo} /></Link>
                </div>
            </li>
            <li className="normal"><p><Link to="/customers">Happy Customers</Link></p></li>
            <li className="normal"><p><Link to="/donate">Donate</Link></p></li>
            <li className="normal"><p><Link to="/contact">Contact</Link></p></li>
        </ul>
    )
}

export default Navbar