import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import cross from '../../images/cross.png'
import sugar from '../../images/sugar.png'
import fondant from '../../images/fondant.png'
import friendly from '../../images/friendly.png'
import eat from '../../images/eat.png'
import vector from '../../images/vector.png'

const WhyUsElem = styled.div`
    width: 100%;
    margin: 0;
    margin-top: 4vw;
    padding: 2vw 0 8vw 0;
    #top {
        position: absolute;
        left: 0;
        transform: translateY(-6vw);
        width: 12.031vw;
    }
    #bottom {
        position: absolute;
        right: 0;
        transform: translateY(6vw);
        width: 12.031vw;
    }
    h1 {
        width: 80%;
        margin: 0 auto;
        line-height: 100%;
        font-size:  3.698vw;
        text-align: center;
    }
    .content {
        width: 84%;
        margin: 0 auto;
        margin-top: 1.458vw;
        display: flex;
        justify-content: space-between; 
        align-items: center;
        .list {
            .item {
                width: 45.313vw;
                margin: 2vw 0;
                display: flex;
                justify-content: space-between; 
                align-items: center;
                img {
                    width: 3.125vw;
                }
                .point {
                    width: 38.594vw;
                    h3 {
                        font-size: 2.292vw;
                        line-height: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    p {
                        font-size: 1.719vw;
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
        .right {
            width: 28.177vw;
            img {
                display: block;
                margin: 0 auto;
                width: 74%;
            }
            button {
                display: block;
                margin: 2vw auto;
                border-radius: 4vw;
                border: none;
                background: #feb5c0;
                color: #474747;
                font-size: 2.292vw;
                width: 100%;
                padding: 0.75vw 0;
                a {
                    text-decoration: none;
                    color: #474747;
                    font-size: 2.292vw;
                }
            }
        }
    }
`

const WhyUs = () => {
    return (
        <WhyUsElem>
            <img id="top" src={vector} />
            <h1>Why Us?</h1>
            <div className="content">
                {/* Left section for WhyUs bullet points */}
                <div className="list">
                    <div className="item">
                        <img src={cross} />
                        <div className="point">
                            <h3>NO Preservatives</h3>
                            <p>(Only Fresh Ingredients)</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={sugar} />
                        <div className="point">
                            <h3>NO Added Sugar</h3>
                            <p>(We care about your dog’s health!)</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={fondant} />
                        <div className="point">
                            <h3>NO Fondants</h3>
                            <p>(They are not at all good for your furry friend!)</p>
                        </div>
                    </div>
                    <div className="item">
                        <img src={friendly} />
                        <div className="point">
                            <h3>Only DOG FRIENDLY Ingredients</h3>
                            <p>(Your dog is our number 1 priority)</p>
                        </div>
                    </div>
                </div>

                {/* Right section for Products Link */}
                <div className="right">
                    <img src={eat} />
                    <button><Link to="/products">View Our Products</Link></button>
                </div>
            </div>
            <img id="bottom" src={vector} />
        </WhyUsElem>
    )
}

export default WhyUs
