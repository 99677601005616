import React, { useState, useEffect }  from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import paw from '../images/paw.png'
import jaw from '../images/jaw.png'
import redvelvet from '../images/redvelvet2.png'
import pizza from '../images/pizza.png'
import bone from '../images/bone.png'
import cake from '../images/cake.png'
import next from '../images/next.png'
import prev from '../images/prev.png'
import call from '../images/call.png'
import insta from '../images/insta.png'

const ProductsElem = styled.div`
    width: 100vw;
    padding-top: 10vw;
    .circle {
        left: -40vw;
        top: -50vw;
        @media only screen and (max-width: 800px) {
            left: -35vw;
            top: -35vw;
        }
        width: 72.92vw;
        height: 72.92vw;
        max-width: 1400px;
        max-height: 1400px;
        position: fixed;
        background: white;
        border-radius: 50%;
        transition: transform 1s ease-in-out;
        z-index: 4;
        .outer {
            position: absolute;
            z-index: 4;
            width:  82.92vw;
            height: 82.92vw;
            max-width: 1400px;
            max-height: 1400px;
            background: white;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);   
        }
        .inner {
            position: absolute;
            z-index: 3;
            width:  92.92vw;
            height: 92.92vw;
            max-width: 1600px;
            max-height: 1600px;
            background-image: linear-gradient(#9f4848, #feb5c0);
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .items {
            img {
                transition: transform 1s ease-in-out;
                position: absolute;
                width: 21vw;
                max-width: 400px;
                z-index: 15;
                top: 0;
                left: 0;
            }
        }
    }
    .details {
        left: 50%;
        transform: translateY(-2vw);
        position: absolute;
        width: 30.104vw;
        min-height: 25vw;
        padding: 1vw 0;
        border: none;
        border-radius: 2vw;
        box-shadow: 0px 0px 10px 0px #c7c7c7;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';

        // Name
        #name {
            width: 22.92vw;
            font-family: 'Poppins';
            font-weight: 600;
            margin: 0;
            padding: 0;
            line-height: 2.8vw;
            font-size: 2.5vw;
            margin-bottom: 1.3vw;
            letter-spacing: 0.12vw;
        }

        // Tagline
        #tagline {
            width: 22.92vw;
            font-style: italic;
            font-weight: 400;
            font-size: 1.2vw;
            color: #747474;
            margin: 0;
            padding: 0;
            line-height: 1.5vw;
            margin-bottom: 2vw;
            letter-spacing: 0.12vw;
        }

        // Line
        #line {
            width: 8.333vw;
            height: 0.260vw;
            background: #9f4848;
            margin-bottom: 1.2vw;
        }

        // Ingredient Heading 
        #inghead {
            line-height: 100%;
            font-size: 1.3vw;
            margin: 0;
            padding: 0;
            margin-bottom: 1.3vw;
            letter-spacing: 0.12vw;
        }

        // Ingredients
        #ingredients {
            margin: 0;
            padding: 0;
            width: 20vw;
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 1.3vw;
            .ingredient {
                box-sizing: border-box;
                background: #9f4848;
                color: white;
                margin: 0 0 0.781vw 0;
                border-radius: 3vw;
                padding: 0.5vw 1.2vw;
                font-size: 0.938vw;
                min-width: 7.5vw;
                max-width: 12.5vw;
                font-weight: 400;
                letter-spacing: 1px;
            }
            .ingredient:nth-child(2n+1) {
                margin-right: 0.3vw;
            }
        }

        // Minimum Order
        #minorder {
            font-family: 'Roboto';
            letter-spacing: 0.1vw;
            font-weight: 700;
            color: #747474;
            margin: 0;
            padding: 0;
            line-height: 100%;
            font-size: 1.094vw;
        }

        // Buttons
        .buttons {
            position: absolute;
            z-index: 20;
            img {
                width: 2.083vw;
                cursor: pointer;
            }
            #next {
                transform: translateX(18vw);
            }
            #prev {
                transform: translateX(-18vw);
            }
        }
    }
    .bottom {
        position: fixed;
        z-index: 5;
        left: 2.5%;
        bottom: 0.2vw;
        @media only screen and (max-width: 800px) {
            top: 40vw;
        }
        width: 95%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left, .right {
            div {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 0.5vw;
                p {
                    font-size: 1vw;
                    font-family: 'Roboto';
                    padding: 0;
                    margin: 0;
                    display: flex;
                    a {
                        color: black;
                        font-weight: 500;
                        text-decoration: none;
                        &:active {
                            color: black;
                            font-weight: 500;
                            text-decoration: none;
                        }
                        &:hover {
                            color: black;
                            font-weight: 700;
                            text-decoration: underline;
                        }
                    }
                }
                img {
                    width: 2vw;
                    margin-right: 1vw;
                }
                #call {
                    width: 1.5vw;
                    margin-right: 1.504vw;
                }
            }
        }
    }
    
    @media only screen and (max-width: 600px) {
        padidng-top: 10vw;
        .circle {
            top: -30vw;
            left: -30vw;
        }
        .details {
            transform: translateY(35vw) translate(-50%, 0);
            width: 50vw;
            min-height: 40vw;
            padding: 4vw 0;
            #name {
                width: 45vw;
                height: 6vw;
                font-weight: 600;
                font-size: 4vw;
                margin-bottom: 1.3vw;
            }
            #tagline {
                width: 30vw;
                height: 6vw;
                font-size: 2vw;
                margin-bottom: 2vw;
                line-height: 120%;
            }
            #line {
                width: 20vw;
                height: 0.60vw;
                margin-bottom: 2vw;
            }
            #inghead {
                height: 3.2vw;
                font-size: 2.3vw;
                margin-bottom: 2vw;
            }
            #ingredients {
                margin: 0;
                padding: 0;
                width: 36vw;
                height: 10vw;
                margin-bottom: 1.3vw;
                .ingredient {
                    margin: 0 0 1.2vw 0;
                    padding: 0.5vw 1.2vw;
                    font-size: 1.8vw;
                    min-width: 7.5vw;
                    max-width: 22vw;
                }
                .ingredient:nth-child(2n+1) {
                    margin-right: 0.3vw;
                }
            }
            #minorder {
                font-size: 2vw;
                height: 3vw;
            }
            .buttons {
                img {
                    width: 3vw;
                    cursor: pointer;
                }
                #next {
                    transform: translateX(20vw);
                }
                #prev {
                    transform: translateX(-20vw);
                }
            }
        }
        .bottom {
            position: fixed;
            z-index: 5;
            width: 45%;
            height: 40vw;
            left: 50%;
            transform: translateY(-15vw);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .left {
                margin-bottom: 5vw;
            }
            .left, .right {
                div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 1vw;
                    p {
                        font-size: 2.4vw;
                        font-family: 'Roboto';
                        padding: 0;
                        margin: 0;
                        display: flex;
                        a {
                            color: black;
                            font-weight: 500;
                            text-decoration: none;
                            &:active {
                                color: black;
                                font-weight: 500;
                                text-decoration: none;
                            }
                            &:hover {
                                color: black;
                                font-weight: 700;
                                text-decoration: underline;
                            }
                        }
                    }
                    img {
                        width: 4vw;
                        margin-right: 1vw;
                    }
                    #call {
                        width: 3.2vw;
                        margin-right: 1.504vw;
                    }
                }
            }
        }
    }
`

const Products = () => {

    const [rotation, setRotation] = useState(0);
    // const [item,setItem] = useState({
    //     name: "Jaw Cake",
    //     tagline: "Why should your friend miss out on cakes!",
    //     ingredients: ["carrot", "papaya", "apple"]
    // })
    const [item,setItem] = useState(null)
    const [index,setIndex] = useState(0)

    useEffect(() => {
        setItem(items[0])
    }, [])

    const items = [
        {
            name: `Paw Cakes`,
            tagline: `Flavored Cupcakes with Our Signature Frosting`,
            ingredients: [`Bananas`, ` Peanut Butter`, `Signature Frosting`, `Pumpkin`],
            min: 6
        },
        {
            name: "Red Velvet Cookies",
            tagline: "Healthy and Delicious Cookies",
            ingredients: ["Beet", "Peanut Butter", "Yogurt"],
            min: 12
        },
        {
            name: "Jaw Cake",
            tagline: "Yumm Carrot Cake with Our Signature Frosting",
            ingredients: [`Carrot`, ` Peanut Butter`, `Signature Frosting`, `Pumpkin`],
            min: 0
        },
        {
            name: "Baked Treats",
            tagline: "Delicious Healthy oats and pumpkin treats",
            ingredients: ["Pumpkin", "Penaut Butter", "Oats"],
            min: 12
        },
        {
            name: "Pizza",
            tagline: "Heathy dog-friendly Pizza",
            ingredients: ["Carrot", "Potato", "Sweet Potato", "Corriander"],
            min: 0
        },
        {
            name: "Frozen Treats",
            tagline: "Healthy dog-friendly Icecream",
            ingredients: ["Bananas", "Peanut Butter", "Yogurt"],
            min: 6
        }
    ]

    const points = [
        {x: "41.46", y: "0"},
        {x: "20.73", y: "35.905"},
        {x: "-20.73", y: "35.905"},
        {x: "-41.46", y: "0"},
        {x: "-20.73", y: "-35.905"},
        {x: "20.73", y: "-35.905"}
    ]
    // const points = [
    //     {x: "10", y: "0"},
    //     {x: "5", y: "8.66"},
    //     {x: "-5", y: "8.66"},
    //     {x: "-10", y: "0"},
    //     {x: "-5", y: "-8.66"},
    //     {x: "5", y: "-8.66"}
    // ]

    // Next Item Function
    const Next = () => {
        let angle = rotation+60;
        setRotation(angle);
        setItem(items[(index+1)%6])
        setIndex((index+1)%6)
        console.log("next", index)
        // console.log("next");
    }
    // Previous Item Function
    const Previous = () => {
        let angle = rotation-60;
        setRotation(angle);
        if (index==0) {
            setIndex(5)
            setItem(items[5])
        }
        else {
            setItem(items[index-1])
            setIndex(index-1)
        }
        console.log("prev", index)
        // console.log("previous");
    }
    return (
        <ProductsElem>
            <div className="circle" style={{transform: `rotate(${rotation+60}deg)`}}>
                <div className="outer"></div>
                <div className="inner"></div>
                <div className="items">
                    <img style={{transform: ` translateX(${points[0].x}vw) translateY(${points[0].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={paw} />
                    <img style={{transform: ` translateX(${points[1].x}vw) translateY(${points[1].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={jaw} />
                    <img style={{transform: ` translateX(${points[2].x}vw) translateY(${points[2].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={pizza} />
                    <img style={{transform: ` translateX(${points[3].x}vw) translateY(${points[3].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={bone} />
                    <img style={{transform: ` translateX(${points[4].x}vw) translateY(${points[4].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={cake} />
                    <img style={{transform: ` translateX(${points[5].x}vw) translateY(${points[5].y}vw) translate(120%, 125%) rotate(${-rotation-60}deg)`}} src={redvelvet} />
                </div>
            </div>
            {item ? (<div className="details">
                <h1 id="name">{item.name}</h1>
                <h3 id="tagline">{item.tagline}</h3>
                <div id="line"></div>
                <h3 id="inghead">Ingredients :</h3>
                <ul id="ingredients">
                    {item.ingredients.map(ing => <li className="ingredient" key={ing}>{ing}</li>)}
                </ul>
                <h5 id="minorder">{item.min!=0 ? `(Minimum order of ${item.min})` : `(No Minimum order)`}</h5>
                <div className="buttons">
                    <img id="prev" onClick={() => Previous()} src={prev} />
                    <img id="next" onClick={() => Next()} src={next} />
                </div>
            </div>) : ""}
            <div className="bottom">
                <div className="left">
                    <div>
                        <img id="call" src={call} />
                        <p>Call/Text : 9512500709</p>
                    </div>
                    <div>
                        <img src={insta} />
                        <p>Instagram :&nbsp; <a target="_blank" href="https://instagram.com/buddys_barkery">buddys_barkery</a></p>
                    </div>
                </div>
                <div className="right">
                    <div>
                        <p>
                            Accepting Online Payments (Paytm)
                        </p>
                    </div>
                    <div>
                        <p>
                            We take care of&nbsp;<Link to="/covid">COVID-19 Regulations</Link>
                        </p>
                    </div>

                </div>
            </div>
        </ProductsElem>
    )
}

export default Products
