import React from 'react'
import Header from './Header'
import Founder from './Founder'
import Footer from '../Footer'

const About = () => {
    return (
        <div>
            <Header />
            <Founder />
            <Footer/>
        </div>
    )
}

export default About
