import React from 'react'
import styled from 'styled-components'
import vector from '../images/vector.png'
import money from '../images/money.png'
import Carousel from 'nuka-carousel'
import next from '../images/next.png'
import prev from '../images/prev.png'
import d1 from '../images/d1.jpg'
import d2 from '../images/d2.jpg'
import d3 from '../images/d3.jpg'
import d4 from '../images/d4.jpg'
import d5 from '../images/d5.jpg'
import Footer from './Footer'

const Elem = styled.div`
    button {
        background: none;
        border: none;
        width: 2vw;
        height: 3vw;
        img {
            width: 2vw;
        }
        cursor: pointer;
    }
    width: 100%;
    padding: 5vw 0 0 0;
    margin: 0;
    #bottom {
        position: relative;
        left: 86%;
        bottom: 7vw;
        width: 12.031vw;
    }
    h1 {
        margin: 0 auto;
        margin-top: 2vw;
        padding: 0.5vw 0;
        width: 88%;
        border-top: 1px solid #474747;
        border-bottom: 1px solid #474747;
        line-height: 100%;
        text-align: center;
        font-family: 'Belleza';
        font-weight: 400;
        font-size: 4vw;
        color: #474747;
        letter-spacing: 2px;
    }
    .content {
        width: 88%;
        margin: 2vw auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0vw;
        .left {
            width: 44vw;
            img {
                display: block;
                margin: 0 auto;
                width: 15vw;
            }
            p {
                text-align: center;
                margin: 0 auto;
                width: 25vw;
                font-weight: 600;
                font-size: 1.2vw;
                margin-bottom: 2vw;
            }
            .quote {
                padding: 2vw 3vw;
                width: 25vw;
                margin: 0 auto;
                border-radius: 1vw;
                border: none;
                box-shadow: 0px 0px 10px 1px #c7c7c7;
                h4 {
                    width: 100%;
                    font-size: 1vw;
                    letter-spacing: 1px;
                    font-style: italic;
                    font-weight: 400;
                }
                p {
                    padding: 0;
                    margin: 0;
                    font-size: 0.6vw;
                    font-weight: 500;
                    text-align: right;
                }
            }
        }
        .right {
            width: 45vw;
            .prev {
                position: absolute;
                left: -4.3vw;
                bottom: 0vw;
            }
            .next {
                position: absolute;
                left: 1vw;
                bottom: 0vw;
            }
            .images {
                position: absolute;
                z-index: 5;
                margin: 2vw auto;
                padding: 1vw 0;
                width: 30vw !important;
                height: 30vw !important;
                border-radius: 3vw;
                .image {
                    border-radius: 3vw;
                    margin: 0 auto;
                    width: 100% !important;
                    height: 25vw !important;
                    &:hover {
                        transform: scale(1.05);
                        transition: all 0.5s ease-in;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 700px) {
        button {
            background: none;
            border: none;
            width: 4vw;
            height: 1vw;
            img {
                width: 4vw;
            }
            cursor: pointer;
        }
        width: 100%;
        padding: 5vw 0 0 0;
        margin: 0;
        #bottom {
            position: relative;
            left: 86%;
            bottom: 0vw;
            width: 12.031vw;
        }
        h1 {
            margin: 0 auto;
            margin-top: 2vw;
            padding: 0.5vw 0;
            width: 88%;
            border-top: 1px solid #474747;
            border-bottom: 1px solid #474747;
            line-height: 100%;
            text-align: center;
            font-family: 'Belleza';
            font-weight: 400;
            font-size: 5vw;
            color: #474747;
            letter-spacing: 2px;
        }
        .content {
            width: 88%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .left {
                width: 60vw;
                img {
                    display: block;
                    margin: 0 auto;
                    width: 30vw;
                }
                p {
                    text-align: center;
                    margin: 0 auto;
                    width: 80%;
                    font-weight: 600;
                    font-size: 2.4vw;
                    margin-bottom: 5vw;
                }
                .quote {
                    margin: 0 auto;
                    margin-top: 5vw;
                    margin-bottom: 3vw;
                    padding: 4vw 6vw;
                    width: 50vw;
                    border-radius: 1vw;
                    border: none;
                    box-shadow: 0px 0px 10px 1px #c7c7c7;
                    h4 {
                        width: 100%;
                        font-size: 2.5vw;
                        letter-spacing: 1px;
                        font-style: italic;
                        font-weight: 400;
                    }
                    p {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        margin-top: 3vw;
                        font-size: 1.6vw;
                        font-weight: 500;
                        text-align: right;
                    }
                }
            }
            .right {
                width: 70vw;
                .prev {
                    position: absolute;
                    left: -8vw;
                    bottom: 4vw;
                }
                .next {
                    position: absolute;
                    left: 1.4vw;
                    bottom: 4vw;
                }
                .images {
                    position: absolute;
                    z-index: 5;
                    margin: 2vw auto;
                    padding: 0;
                    width: 60vw !important;
                    height: 70vw !important;
                    border-radius: 3vw;
                    .image {
                        border-radius: 3vw;
                        margin: 0 auto;
                        width: 60vw !important;
                        height: 55vw !important;
                        &:hover {
                            transform: scale(1.05);
                            transition: all 0.5s ease-in;
                        }
                    }
                }
            }
        }
    }
`

const Donate = () => {
    return (
        <Elem>
            <h1>KINDNESS . LOVE . HUMANITY</h1>
            <div className="content">
                <div className="left">
            <div className="quote">
                <h4>“The love for all living creatures is the most noble attribute of man.”</h4>
                <p>- Charles Darwin</p>
            </div>
                    <img src={money} />
                    <p>
                        A part of each order goes as a donation for the welfare of dogs in Ahmedabad.
                    </p>
                </div>
                <div className="right">
                    <Carousel
                        className="images"

                        renderCenterLeftControls={({ previousSlide }) => (
                            <button className="prev" onClick={previousSlide}><img src={prev} /></button>
                        )}

                        renderCenterRightControls={({ nextSlide }) => (
                        <button className="next" onClick={nextSlide}><img src={next} /></button>
                        )}

                        slidesToShow="1"
                        autoplay={true}
                        wrapAround={true}
                    >
                        <div className="image" style={{backgroundImage: `url(${d1})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                        <div className="image" style={{backgroundImage: `url(${d2})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                        <div className="image" style={{backgroundImage: `url(${d3})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                        <div className="image" style={{backgroundImage: `url(${d4})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                        <div className="image" style={{backgroundImage: `url(${d5})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}></div>
                    </Carousel>
                </div>
            </div>
            <img id="bottom" src={vector} />
            <Footer />
        </Elem>
    )
}

export default Donate
